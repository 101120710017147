import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { Helmet } from 'react-helmet';
import Page from '../container/page';
import '../scss/entry.scss'

const query = graphql`
query AllStillImages {
  allStrapiStillContent {
    nodes {
      Content {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: BLURRED, formats: [WEBP, PNG], layout:FULL_WIDTH)
        }
      }
    }
  }
}
`

export default function Stills() {
  return [
    <Helmet>
      <title>Joshua Carroll | Stills</title>
    </Helmet>,
    <Page>
      <div className="stills-container">
        <StaticQuery
        query={query}
        render={
          (d) => {
            const data = d.allStrapiStillContent.nodes
            return data.map(_ => {
              return (
                <GatsbyImage image={getImage(_.Content)} loading="lazy" />
                )
            })
          }
        } />
      </div>
    </Page>
  ]
}
